import { createRouter, createWebHistory } from 'vue-router'

import fuelManagementRouter from '@/router/modules/fuel-management'
import vehicleRouter from '@/router/modules/vehicle'
import trailerRouter from '@/router/modules/trailer'
import fleetAccessSettingsRouter from '@/router/modules/fleet-access-settings'
import GPSTrackingRouter from '@/router/modules/gps-tracking'
import driverRouter from '@/router/modules/driver'
import userSettingsRouter from '@/router/modules/user-settings'
import notificationRouter from '@/router/modules/notification'
import reportRouter from '@/router/modules/report'
import eventCalendarRouter from '@/router/modules/event-calendar'
import checklistRouter from '@/router/modules/checklist'
import breakdownSupportRouter from '@/router/modules/breakdown-support'
import smartAnalyticsRouter from '@/router/modules/smart-analytics'
// import coldChainMonitoringRouter from '@/router/modules/cold-chain-monitoring'
import insuranceRouter from '@/router/modules/insurance'
import tollRouter from '@/router/modules/toll'
import safetyRouter from '@/router/modules/safety'
import routeRouter from '@/router/modules/route'
import { getLite } from '@/utils/auth'

export const constantRoutes = [
  {
    path: '/test',
    component: () => import('@/views/test.vue'),
    name: 'test',
    hidden: true
  },
  {
    path: '/redirect',
    component: () => import('@/views/redirect/redirect'),
    name: 'Redirect',
    hidden: true
  },
  {
    path: '/login',
    component: () => import('@/views/Login/login'),
    name: 'LOGIN',
    meta: {
      bypass: true
    },
    hidden: true
  },
  userSettingsRouter,
  notificationRouter,
  {
    path: '/',
    component: () => import('@/views/KPIDashboard/kpi-dashboard'),
    name: 'KPIDashboard',
    meta: { title: 'KPIDashboard', icon: 'dashboard', parent: { title: 'KPIDashboard', icon: 'dashboard'} }
  },
  smartAnalyticsRouter,
  // coldChainMonitoringRouter,
  eventCalendarRouter,
  GPSTrackingRouter,
  /*{ //add here first, will cause normal de login display so its bad
    path: '/gps-tracking/full-map-tracking-lite/:id?',
    component: () => import('@/views/GPS-Tracking/FullMapTracking/full-map-tracking-lite.vue'),
    name: 'FullMapTrackingLite',
    meta: { title: 'FullMapTrackingLite', icon: 'GpsTracking'}
  },
  {
    path: '/gps-tracking/vehicle-tracking-list',
    component: () => import('@/views/GPS-Tracking/VehicleTrackingList/vehicle-tracking-list.vue'),
    name: 'VehicleTrackingList',
    meta: { title: 'VehicleTrackingList', icon: 'GpsTracking'}
  },*/
  vehicleRouter,
  trailerRouter,
  driverRouter,
  checklistRouter,
  reportRouter,
  fuelManagementRouter,
  breakdownSupportRouter,
  fleetAccessSettingsRouter,
  insuranceRouter,
  safetyRouter,
  routeRouter,
  tollRouter,
  {
    path: '/vehicle-certificate',
    name: 'VehicleCertificate',
    component: () => import('@/certificate/vehicle-certificate.vue'),
    hidden: true
  },
  {
    path: '/401',
    name: 'error-401',
    component: () => import('@/views/ErrorPage/401-Error.vue'),
    hidden: true
  },
  {
    path: '/404',
    name: 'error-404',
    component: () => import('@/views/ErrorPage/404-Error.vue'),
    hidden: true
  }
]

export const asyncRoutes = [

]

export const liteRoutes = [
  {
    path: '/redirect',
    component: () => import('@/views/redirect/redirect'),
    name: 'Redirect',
    hidden: true
  },
  {
    path: '/login',
    component: () => import('@/views/Login/login'),
    name: 'LOGIN',
    meta: {
      bypass: true
    },
    hidden: true
  },
  {
    path: '/gps-tracking/full-map-tracking-lite',
    component: () => import('@/views/GPS-Tracking/FullMapTracking/full-map-tracking-lite.vue'),
    name: 'FullMapTrackingLite',
    meta: { title: 'FullMapTrackingLite', icon: 'GpsTracking'}
},
  {
    path: '/gps-tracking/vehicle-tracking-list-lite',
    component: () => import('@/views/GPS-Tracking/VehicleTrackingList/vehicle-tracking-list-lite.vue'),
    name: 'VehicleTrackingListLite',
    meta: { title: 'VehicleTrackingListLite', icon: 'vehicle'}
  },
  {
    path: '/vehicle-certificate',
    name: 'VehicleCertificate',
    component: () => import('@/certificate/vehicle-certificate.vue'),
    hidden: true
  },
  {
    path: '/401',
    name: 'error-401',
    component: () => import('@/views/ErrorPage/401-Error.vue'),
    hidden: true
  },
  {
    path: '/404',
    name: 'error-404',
    component: () => import('@/views/ErrorPage/404-Error.vue'),
    hidden: true
  }
  ]

  const isLite = getLite()
  const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BUILD_PATH),
    // history: createWebHistory(),
    routes: isLite ? liteRoutes : constantRoutes
  })

  export function updateRoutes(isLite) {
    const newRoutes = isLite ? liteRoutes : constantRoutes;
  
    // Remove existing routes
    router.getRoutes().forEach((route) => {
      router.removeRoute(route.name);
    });
  
    // Add new routes
    newRoutes.forEach((route) => {
      router.addRoute(route);
    });
  }
  

export default router