const addInfo = {
  title: 'Trailer',
  icon: 'trailer'
}
const trailerRouter = {
  path: '/trailer',
  meta: {
    title: 'Trailer',
    icon: 'trailer'
  },
  children: [
    {
      path: 'manage-trailer',
      component: () => import('@/views/trailer/ManageTrailer/manage-trailer'),
      name: 'ManageTrailer',
      meta: { title: 'ManageTrailer', parent: addInfo }
    },
    {
      path: 'manage-trailer-form/:id?',
      component: () => import('@/views/trailer/ManageTrailer/manage-trailer-form'),
      name: 'ManageTrailerForm',
      hidden: true,
      meta: { title: 'ManageTrailerForm', parent: addInfo, activeMenu: '/trailer/manage-trailer', enableBack: true, backPage: 'ManageTrailer'  }
    },
    // {
    //   path: 'trailer-tracking-page',
    //   component: () => import('@/views/trailer/TrailerTrackingPage/trailer-tracking-page'),
    //   name: 'TrailerTrackingPage',
    //   meta: { title: 'TrailerTrackingPage', parent: addInfo }
    // },
    // {
    //   path: 'trailer-profile/:id',
    //   component: () => import('@/views/trailer/TrailerTrackingPage/trailer-profile'),
    //   name: 'TrailerProfile',
    //   hidden: true,
    //   meta: { title: 'TrailerProfile', parent: addInfo, activeMenu: '/trailer/trailer-tracking-page' }
    // },
  ]
}

export default trailerRouter
