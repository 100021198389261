const addInfo = {
  title: 'BreakdownSupport',
  icon: 'breakdown-support'
}
const breakdownSupportRouter = {
  path: '/breakdown-support',
  meta: {
    title: 'BreakdownSupport',
    icon: 'breakdown-support'
  },
  children: [
    {
      path: 'manage-workshop',
      component: () => import('@/views/breakdown-support/ManageWorkshop/manage-workshop'),
      name: 'ManageWorkshop',
      meta: { title: 'ManageWorkshop', parent: addInfo }
    },
    {
      path: 'manage-workshop-form/:id?',
      component: () => import('@/views/breakdown-support/ManageWorkshop/manage-workshop-form'),
      name: 'ManageWorkshopForm',
      hidden: true,
      meta: { title: 'ManageWorkshopForm', parent: addInfo, activeMenu: '/breakdown-support/manage-workshop', enableBack: true, backPage: 'ManageWorkshop' }
    },
    {
      path: 'manage-workshop-rating',
      component: () => import('@/views/breakdown-support/ManageWorkshopRating/manage-workshop-rating'),
      name: 'ManageWorkshopRating',
      meta: { title: 'ManageWorkshopRating', parent: addInfo }
    }
  ]
}

export default breakdownSupportRouter