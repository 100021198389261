const tollRouter = {
  path: '/toll',
  meta: {
    title: 'Toll',
    icon: 'toll'
  },
  children: [
    {
      path: 'manage-toll',
      component: () => import('@/views/toll/ManageToll/manage-toll'),
      name: 'ManageToll',
      meta: { title: 'ManageToll' }
    },
    // {
    //   path: 'manage-toll-form/:id?',
    //   component: () => import('@/views/toll/ManageToll/manage-toll-form'),
    //   name: 'ManageTollForm',
    //   hidden: true,
    //   meta: { title: 'ManageTollForm', activeMenu: '/toll/manage-toll', enableBack: true, backPage: 'ManageToll' }
    // },
    {
      path: 'manage-toll-group',
      component: () => import('@/views/toll/ManageTollGroup/manage-toll-group'),
      name: 'ManageTollGroup',
      meta: { title: 'ManageTollGroup' }
    },
    {
      path: 'toll-group-form/:id?',
      component: () => import('@/views/toll/ManageTollGroup/toll-group-form'),
      name: 'TollGroupForm',
      hidden: true,
      meta: { 
          title: 'TollGroupForm', 
          
          activeMenu: '/toll/manage-toll-group',         
          enableBack: true,
          backPage: 'ManageTollGroup'
      }
    },
    // {
    //   path: 'driver-app',
    //   name: 'DriverApp',
    //   meta: { title: 'DriverApp' },
    //   children: [
    //     {
    //       path: 'check-in-out',
    //       component: () => import('@/views/driver/DriverApp/check-in-out'),
    //       name: 'CheckInOut',
    //       meta: { title: 'CheckInOut', icon: 'subsubmenu' }
    //     },
    //     {
    //       path: 'checklist-approval',
    //       component: () => import('@/views/driver/DriverApp/checklist-approval'),
    //       name: 'ChecklistApproval',
    //       meta: { title: 'ChecklistApproval', icon: 'subsubmenu' }
    //     },
    //     {
    //       path: 'driver-claim-report',
    //       component: () => import('@/views/driver/DriverApp/driver-claim-report'),
    //       name: 'DriverClaimReport',
    //       meta: { title: 'DriverClaimReport', icon: 'subsubmenu' }
    //     },
    //     {
    //       path: 'driver-tracing',
    //       component: () => import('@/views/driver/DriverApp/driver-tracing'),
    //       name: 'DriverTracing',
    //       meta: { title: 'DriverTracing', icon: 'subsubmenu' }
    //     }
    //   ]
    // }
  ]
}

export default tollRouter
