export default {
    title: {
        KPIDashboard: 'KPI Dashboard',
        GpsTracking: 'GPS Tracking',
        EventCalendar: 'Event Calendar',
        EventList: 'Event List',
        Calendar: 'Calendar',
        FullMapTracking: 'Full Map Tracking',
        MultiVehicleTracking: 'Multi Vehicle Tracking',
        VehicleTrackingList: 'Vehicle Tracking List',
        HistoricalPlayback: 'Historical Playback',
        HistoricalPlaybackDetails: 'Historical Playback Details',
        PtoLog: 'PTO Log',
        PoiManagement: 'POI Management',
        GeofenceManagement: 'Geofence Management',
        Vehicle: 'Vehicle',
        ManageVehicle: 'Manage Vehicle',
        MaintenanceLog: 'Maintenance Log',
        Overview: 'Overview',
        ServiceLog: 'Service Log',
        VehicleLog: 'Vehicle Log',
        MaintenanceForm: 'Maintenance Form',
        AddMaintenanceForm: 'Add Maintenance Form',
        EditMaintenanceForm: 'Edit Maintenance Form',
        Tools: 'Tools',
        MaintenanceCategory: 'Maintenance Category',
        Driver: 'Driver',
        ManageDriver: 'Manage Driver',
        ManageDriverForm: 'Driver Form',
        AddDriverForm: 'Add Driver Form',
        EditDriverForm: 'Edit Driver Form',
        DriverDetails: 'Driver Details',
        DriverInquiry: 'Driver Inquiry',
        DriverApp: 'Driver App',
        CheckInOut: 'Check In / Out',
        ChecklistApproval: 'Checklist Approval',
        DriverClaimReport: 'Driver Claim Report',
        DriverTracing: 'Driver Tracing',
        Trailer: 'Trailer',
        ManageTrailer: 'Manage Trailer',
        TrailerTrackingPage: 'Trailer Tracking Page',
        FuelManagement: 'Fuel Management',
        FuelLog: 'Fuel Log',
        FuelConsumptionHistory: 'Fuel Consumption History',
        RefuelManagement: 'Refuel Management',
        FuelPriceManagement: 'Fuel Price Management',
        FuelSpend: 'Fuel Spend',
        FleetAccessSettings: 'Fleet Access Settings',
        FleetGroupForm: 'Fleet Group Form',
        AddFleetGroupForm: 'Add Fleet Group Form',
        EditFleetGroupForm: 'Edit Fleet Group Form',
        ManageUser: 'Manage User',
        UserForm: 'User Form',
        AddUserForm: 'Add User Form',
        EditUserForm: 'Edit User Form',
        FleetGroupManagement: 'Fleet Group Management',
        CompanyManagement: 'Company Management',
        CompanyForm: 'Company Form',
        AddCompanyForm: 'Add Company Form',
        EditCompanyForm: 'Edit Company Form',
        EmailNotificationSettings: 'Email Notification Settings',
        EmailNotificationSettingsForm: 'Email Notification Settings Form',
        AddEmailNotificationSettingsForm: 'Add Email Notification Settings Form',
        EditEmailNotificationSettingsForm: 'Edit Email Notification Settings Form',
        RolesManagement: 'Roles Management',
        RolesForm: 'Roles Form',
        AddRolesForm: 'Add Roles Form',
        EditRolesForm: 'Edit Roles Form',
        PackageManagement: 'Package Management',
        PackageForm: 'Package Form',
        AddPackageForm: 'Add Package Form',
        EditPackageForm: 'Edit Package Form',
        AuditLogs: 'Audit Logs',
        Report: 'Report',
        VehicleIndustry: 'Vehicle Industry',
        VehicleForm: 'Vehicle Form',
        AddVehicleForm: 'Add Vehicle Form',
        EditVehicleForm: 'Edit Vehicle Form',
        ManageTracker: 'Manage Tracker',
        TrackerForm: 'Tracker Form',
        AddTrackerForm: 'Add Tracker Form',
        EditTrackerForm: 'Edit Tracker Form',
        MapInstance: 'Map Instance',
        MapInstanceDetails: 'Map Instance Details',
        UserSettings: 'User Settings',
        UserProfile: 'User Profile',
        HelpCenter: 'Help Center',
        DeviceSettings: 'Device Settings',
        FirmwareManagement: 'Firmware Management',
        AlarmHistory: 'Alarm History',
        AlarmDetails: 'Alarm Details',
        Notification: 'Notification',
        Checklist: 'Checklist',
        ManageChecklist: 'Manage Checklist',
        ManageChecklistForm: 'Checklist Form',
        AddChecklistForm: 'Add Checklist Form',
        EditChecklistForm: 'Edit Checklist Form',
        CompanyChecklist: 'Company Checklist',
        BreakdownSupport: 'Breakdown Support',
        ManageWorkshop: 'Manage Workshop',
        ManageWorkshopForm: 'Manage Workshop Form',
        AddWorkshopForm: 'Add Workshop Form',
        EditWorkshopForm: 'Edit Workshop Form',
        ManageWorkshopRating: 'Manage Workshop Rating',
        SmartAnalytics: 'Smart Analytics',
        OperationalPerformance: 'Operational Performance',
        VehiclePerformance: 'Vehicle Performance',
        DriverPerformance: 'Driver Performance',
        ColdChainMonitoring: 'Cold Chain Monitoring',
        Toll: 'Toll',
        ManageToll: 'Manage Toll',
        ManageTollGroup: 'Manage Toll Group',
        TollGroupForm: 'Toll Group Form',
        Insurance: 'Insurance',
        Safety: 'Safety',
        EventDetails: 'Event Detail',
        Speeding: 'Speeding Behavior',
        Route: 'Route',
        ManageRoute: 'Manage Route',
        ManageRouteDetail: 'Route',
        EditManageRouteDetail: 'Edit Route',
        ManageRouteGroup: 'Manage Group Route',
        RouteGroupForm: 'Add New Route Group',
        RouteGroupDetail: 'Route Group',
        AssignRouteGroup: 'Assign Route Group', 
        FullMapTrackingLite: 'Full Map Tracking',
        VehicleTrackingListLite: 'Vehicle Tracking List',
        ManageRouteForm: 'Manage Route Form',
    },
    genderType: {
        MALE: 'Male',
        FEMALE: 'Female',
        3: 'Rather Not Say'
    },
    employmentStatus: {
        1: 'Active',
    },
    driverType: {
        DIRECT: 'Direct Driver',
        FEEDER: 'Feeder Driver'
    },
    approvalStatus: {
        1: 'Approved',
        2: 'Rejected',
        3: 'Pending'
    },
    serviceType: {
        1: 'Normal',
        2: 'Urgent',
        3: 'Warranty',
        4: 'Servicing',
        5: 'Troubleshooting',
        6: 'Puspakom',
        7: 'Accident',
        8: 'Total Lost',
        9: 'Engine Overhaul'
    },
    rateStatus: {
        1: 'Excellent',
        2: 'Good',
        3: 'Acceptable'
    },
    portable: 'Portable',
    portableType: {
        0: 'General',
        1: 'Portable Device',
        2: 'Trailer Tied'
    },
    vehicleStatus: {
        null: 'Working',
        parking: 'Parking',
        accident: 'Accident',
        deviceNotOnline: 'Device Not Online',
        repair: 'Repair',
        immobilized: 'Immobilized'
    },
    integrationType: {
        vehicle: 'Vehicle',
        user: 'User',
        group: 'Fleet Group'
    },
    faq: {
        dashboard_question_1: "What is KPI Dashboard?",
        dashboard_answer_1: "KPI Dashboard allows user to have a brief details on vehicles and drives. Information that are available in this page are No. of Today Active Vehicle, Maintenance Alert, Suspicious Activity, No. of Alerts, Chart of Daily Utilization of Vehicles, Fuel Usage &amp; Mileage, Top 5 list of Idling Time, Fuel Consumption, High Speed.",
        dashboard_question_2: "What are the information shows in the 'No. of Today Active Vehicle'?",
        dashboard_answer_2: "'No. of Today Active Vehicle' shows the total number of active vehicle today out of the total number of vehicles registered in the system. In its detailed view, there will be a list of active vehicles including the plate number and the mileage of the vehicle.",
        dashboard_question_3: "What are the information shows in the 'Suspicious Activity?",
        dashboard_answer_3: "'Suspicious Activity' shows the details of suspicious activities detected on vehicles. The suspicious activity can be assign to be handle by user or other staffs through email address.",
        dashboard_question_4: "What are the information show in the 'Maintenance Alert'?",
        dashboard_answer_4: "'Maintenance Alert' shows the details of upcoming maintenance of vehicle. The maintenance alert can be assign to be handle by user or other staffs through email address.",
        dashboard_question_5: "How To Change Time Frame for KPI Dashboard Reports?",
        dashboard_answer_5: "To change the period of time of the KPI Dashboard's reports, click on the drop down list 'Yesterday' and select the time frame from 'Yesterday', 'Last 7 days', 'This Week', 'Last 30 Days', and 'This Month', then press 'Search' button on the right.",
        event_calendar_question_1: "What is Event Calendar?",
        event_calendar_answer_1: "Event Calendar summarize the expiration date such as insurance, permit, road tax and etc. in a calendar. It provides a more simple view that enables user to keep track on the upcoming events in a simpler way.",
        event_calendar_question_2: "Where Can I Get the Details of the Event?",
        event_calendar_answer_2: "To get the details of the event, click on the event and it will shows a list of vehicle that are involved in the event. Click on the vehicle number will redirect you to the vehicle details where all the information are listed.",
        gps_tracking_question_1: "Where is the Vehicle Current Location?",
        gps_tracking_answer_1: "Go to 'GPS Tracking' > 'Vehicles Tracking List'. Select and click on the registration number of vehicle to track the vehicle from the list.",
        gps_tracking_question_2: "How to Track Multiple Vehicle in a Single Page?",
        gps_tracking_answer_2: "Go to 'GPS Tracking' > 'Multi-Vehicle Tracking'. Select the registration number of vehicle to track the vehicle from the drop-down list. User can be track up to 4 vehicles in a single page.",
        gps_tracking_question_3: "How to Filter Vehicle by Groups?",
        gps_tracking_answer_3: "To filter vehicle by groups, press '-Please select a group-' drop down list. Pick a group then press 'Search' on the right to process the filtering.",
        gps_tracking_question_4: "How to Find the Location of a Coordinate or an Address?",
        gps_tracking_answer_4: "To look for the location of a coordinate or an address, just click on the address or coordinate. The location will then be shown in Google Map.",
        gps_tracking_question_5: "Where Can I Track All Vehicles Registered?",
        gps_tracking_answer_5: "It is possible to track all available vehicles by go to 'GPS Tracking' > 'Full Vehicle Tracking Map'.",
        gps_tracking_question_6: "How to View the Whole Journey of a Vehicle in a Day?",
        gps_tracking_answer_6: "Go to 'GPS Tracking' > 'Historical Playback' and select the vehicle registration number to be tracked. Next, select the date and time and desire playback speed before clicking 'Search' button. After the playback started, click on the 'Full Track' on the right panel to display the full track.",
        vehicle_question_1: "Where and How to Add New Vehicle?",
        vehicle_answer_1: "Go to 'Vehicle' > 'Add new vehicle', enter the information required for the form and press 'Submit.",
        vehicle_question_2: "Where and How to Manage Vehicle?",
        vehicle_answer_2: "Go to 'Vehicle' > 'Manage vehicles', select a vehicle to be edited. Enter the updated information of the vehicle into the form and press 'Save'.",
        vehicle_question_3: "Where Can I Get Vehicle Maintenance Log?",
        vehicle_answer_3: "Go to 'Vehicle' > 'Vehicle Maintenance Log', all the information of the vehicle will be listed in a table.",
        vehicle_question_4: "Where Can I Download/Print Vehicle Maintenance Log?",
        vehicle_answer_4: "Go to 'Vehicle' > 'Vehicle Maintenance Log', on the right top corner there will be 'CSV', 'Excel', 'PDF' format that are available for download and a 'Print' to print the Vehicle Maintenance Log.",
        driver_question_1: "Where and How to Add New Driver?",
        driver_answer_1: "Go to 'Driver' > 'Add new driver', enter the information required for the form and press 'Submit.",
        driver_question_2: "Where and How to Manage Driver?",
        driver_answer_2: "Go to 'Driver' > 'Manage Drivers', select a driver to be edited and press 'Edit Profile' on the top right. Enter the updated information of the driver into the form and press 'Save.",
        driver_question_3: "Where Can I Get Driver Log?",
        driver_answer_3: "Go to 'Driver' > 'Driver Log', the information of the will be listed in a table.",
        fleet_access_setting_question_1: "Where to Add and Manage Users and Fleet Groups?",
        fleet_access_setting_answer_1: "Go to 'Fleet Access Settings' and go to the specific user group management. Then select to Add or Manage the user group. Follow the instructions and fill in the details needed.",
        fuel_management_question_1: "Where Can I Review Fuel Consumption Report of A Vehicle?",
        fuel_management_answer_1: "Go to 'Fuel Management' > 'Fuel Consumption Report', select a vehicle and press 'Search'. All the information of the fuel consumption of the vehicle will be shown in a table below.",
        fuel_management_question_2: "Where Can I Review Refuel Report of Vehicles?",
        fuel_management_answer_2: "Go to 'Fuel Management' > 'Refuel Report', the table below will list the refuel details of vehicles, sorted from the lastest by default.",
        fuel_management_question_3: "How to Narrow Down the Refuel Report of a Specific Vehicle?",
        fuel_management_answer_3: "Go to 'Fuel Management' > 'Refuel Report', Select the vehicle registration number and also the specific time range then press the 'Search' button. The table below will shows all the Refuel information of the vehicle in the specific time period.",
        report_question_1: "How to Download a Report?",
        report_answer_1: "Reports are able to be downloaded by pressing 'Export' or 'Export Full Report' on the right top corner of the view. By default, the reports are in excel format.",
        report_question_2: "How Can I Download Report to Another Format?",
        report_answer_2: "To download a report in other format, press the 'CSV' ,'Excel' ,or 'PDF' shown on the top right corner of the report details.",
        report_question_3: "How Can I Print a Report?",
        report_answer_3: "To print a report, press the 'Print' shown on the top right corner of the report details.",
    },
    geofenceModule: {
        checkpoint: 'Checkpoint',
        checkpointwithoutnotification: 'Checkpoint Without Notification',
        prohibited: 'Prohibited',
        inout: 'In/Out',
        in: 'In',
        out: 'Out',
        color: {
            null: 'Default',
            black: 'Black',
            gray: 'Gray',
            red: 'Red',
            crimson: 'Crimson',
            blue: 'Blue',
            cyan: 'Cyan',
            olive: 'Olive',
            green: 'Green',
            lime: 'Lime',
            yellow: 'Yellow',
            orange: 'Orange',
            salmon: 'Salmon',
            purple: 'Purple',
        }
    },
    please_enter_username: 'Please Enter Username',
    please_enter_password: 'Please Enter Password',
    login: 'Login',
    logout: 'Logout',
    switchLang: 'Switch Language',
    permit_sah: 'Permit Sah',
    minyak_engine: 'Minyak Engine',
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
    no_data: 'No Data',
    start_date: 'Start Date',
    start_time: 'Start Time',
    end_date: 'End Date',
    end_time: 'End Time',
    playback_the_track_when_speed: 'Playback the track when speed >=',
    filter: 'Filter',
    add: 'Add',
    new: 'New',
    edit: 'Edit',
    toll: 'Toll',
    edit_profile: 'Edit Profile',
    back: 'Back',
    cancel: 'Cancel',
    cancel_action: 'Cancel Action',
    delete: 'Delete',
    are_you_sure_delete: 'Are you sure to delete <b>%{name}</b>',
    are_you_sure_resend_command_for: 'Are you sure to resend command for <b>%{name}</b>',
    are_you_sure_resend_all_command: 'Are you sure to resend all command',
    are_you_sure_cancel_all_command: 'Are you sure to cancel all command',
    save: 'Save',
    search: 'Search',
    apply: 'Apply',
    clear_all: 'Clear All',
    select_all: 'Select All',
    deselect_all: 'Deselect All',
    draft: 'Draft',
    completed: 'Completed',
    reset: 'Reset',
    export: 'Export',
    column: 'Columns',
    action: 'Action',
    total: 'Total',
    online: 'Online',
    offline: 'Offline',
    active: 'Active',
    inactive: 'Inactive',
    select_a_group: 'Select a Group',
    select_a_vehicle: 'Select a Vehicle',
    select_a_vehicle_type: 'Select a Vehicle Type',
    select_a_engine_type: 'Select a Engine Type',
    select_a_plate_no: 'Select a Plate No',
    select_a_toll_name: 'Select a Toll Name',
    select_driver: 'Select Driver',
    toll_name: 'Toll Name',
    name: 'Name',
    summary: 'Summary',
    plate_no: 'Plate No',
    registration_plate_no: 'Registration Plate No',
    plate_no_label: 'Plate No Label',
    tied_trailer: 'Tied Trailer',
    driver: 'Driver',
    new_driver: 'New Driver',
    group: 'Group',
    device_id: 'Device ID',
    sim_card_no: 'Sim Card No',
    card_no: 'Card No',
    registration_date: 'Registration Date',
    installation_date: 'Installation Date',
    warranty_installation_date: 'Warranty Installation Date',
    fuel_consumption: 'Fuel Consumption',
    car_utilization: 'Car Utilization',
    safety_level: 'Safety Level',
    group_name: 'Group Name',
    ignition: 'Ignition',
    first_drive: 'First Drive',
    pto: 'PTO',
    time: 'Time',
    idle_duration: 'Idle Duration',
    speed: 'Speed',
    device_status: 'Device Status',
    fuel: 'Fuel',
    today_mileage: 'Today Mileage',
    address: 'Address',
    custom_view: 'Custom View',
    set_stop_duration: 'Set Stop Duration',
    full_map: 'Full Map',
    date_time: 'Date Time',
    fuel_level: 'Fuel Level',
    acc: 'Acc',
    mileage_km: 'Mileage (KM)',
    start_point: 'Start Point',
    end_point: 'End Point',
    starting_mileage: 'Starting Mileage',
    location: 'Location',
    idling_duration: 'Idling Duration',
    parking_duration: 'Parking Duration',
    nombor: 'No.',
    daily_summary: 'Daily Summary',
    live_tracking: 'Live Tracking',
    view_profile: 'View Profile',
    fuel_report: 'Fuel Report',
    pto_log: 'PTO Log',
    maintenance_log: 'Maintenance Log',
    stop_recover_engine: 'Stop Recover Engine',
    assign_unassign_driver: 'Assign Unassign Driver',
    assign_unassign_tng_card: 'Assign Unassign TNG Card',
    create_sharable_view: 'Create Sharable View',
    live_video: 'Live Video',
    status: 'Status',
    engine_type: 'Engine Type',
    vehicle_type: 'Vehicle Type',
    normal: 'Normal',
    idling: 'Idling',
    parking: 'Parking',
    contact: 'Contact',
    phone_no: 'Phone Number',
    vehicle: 'Vehicle',
    poi: 'POI',
    traffic: 'Traffic',
    geofence: 'Geofence',
    search_by_plate_no: 'Search by Plate Number',
    search_by_poi: 'Search by POI Name',
    search_by_geofence: 'Search by Geofence Name',
    all: 'All',
    moving: 'Moving',
    disconnected: 'Disconnected',
    geofence_entry_ot: 'Geofence Entry OT',
    speeding: 'Speeding',
    ICE: 'Internal Combustion Engine (ICE)',
    PHEV: 'Plug-in Hybrid Electric Vehicle (PHEV)',
    EV: 'Electric Vehicle (EV)',
    car: 'Car',
    bus: 'Bus',
    truck: 'Truck',
    machinery: 'Machinery',
    machinery_crane: 'Machinery',
    mixer: 'Mixer Truck',
    van: 'Van',
    crane: 'Crane',
    motor: 'Motorcycle',
    strong: 'Strong',
    medium: 'Medium',
    weak: 'Weak',
    healthy: 'Healthy',
    aware: 'Aware',
    abnormal: 'Abnormal',
    'open circuit': 'Open Circuit',
    'short circuit': 'Short Circuit',
    unknown: 'Unknown',    
    need_replacement: 'Need replacement',
    full_charge: 'Full Charge',
    charging: 'Charging',
    low_charge: 'Low Charge',
    and_above: 'and above',
    and_below: 'and below',
    engine_service_date: 'Engine Service Date',
    transmission_gear_box_service_date: 'Transmission / Gear Box Service Date',
    electrical_system_service_date: 'Electrical System Service Date',
    please_select_vehicle: 'Please select vehicle first...',
    show_route: 'Show Route',
    hide_route: 'Hide Route',
    live_data: 'Live Data',
    maintenance: 'Maintenance',
    add_poi: 'Add POI',
    poi_details: 'POI Details',
    poi_name: 'POI Name',
    poi_latitude: 'POI Latitude',
    poi_longitude: 'POI Longitude',
    poi_icon: 'POI Icon',
    poi_radius: 'POI Radius',
    poi_contacter: 'POI Contacter',
    poi_phone: 'POI Phone',
    poi_address: 'POI Address',
    poi_remark: 'POI Remark',
    total_cost: 'Total Cost',
    total_spend: 'Total Spend',
    code: 'Code',
    description: 'Description',
    maintenance_category: 'Maintenance Category',
    maintenance_form: 'Maintenance Form',
    form_no: 'Form No',
    workshop: 'Workshop',
    contact_no: 'Contact No',
    service_type: 'Service Type',
    cost_of_repair_rm: 'Cost of Repair (RM)',
    urgent: 'Urgent',
    warranty: 'Warranty',
    servicing: 'Servicing',
    troubleshooting: 'Troubleshooting',
    puspakom: 'Puspakom',
    accident: 'Accident',
    total_lost: 'Total Lost',
    engine_overhaul: 'Engine Overhaul',
    tyre: 'Tyre',
    maintenance_form_details: 'Maintenance Form Details',
    repair_maintenance_order: 'Repair Maintenance Order',
    vehicle_condition_remark: 'Vehicle Condition Remark',
    please_enter: 'Please enter...',
    please_select: 'Please select',
    partnership_workshop: 'Partnership Workshop',
    drive_in_date_time: 'Drive In Datetime',
    drive_out_date_time: 'Drive Out Datetime',
    technician: 'Technician',
    drop_off_driver: 'Drop Off Driver',
    pickup_driver: 'Pickup Driver',
    estimate_complete_date: 'Estimate Complete Date',
    item: 'Item',
    good: 'Good',
    front_head_lamps: 'Front Head Lamps',
    rear_tails_lamps: 'Rear Tails Lamps',
    adjust_brake_pm_trailer: 'Adjust Brake PM / Trailer',
    top_up_engine_oil: 'Top Up Engine Oil',
    top_up_aircond_gas: 'Top Up Aircond Gas',
    rear_front_view_mirror: 'Rear Front View Mirror',
    air_leaking: 'Air Leaking',
    windscreen: 'Windscreen',
    both_side_windows: 'Both Side Windows',
    wiring_problem: 'Wiring Problem',
    gear_problem: 'Gear Problem',
    wiper_blade: 'Wiper Blade',
    permit_date: 'Permit Date',
    roadtax_date: 'Roadtax Date',
    sgp_roadtax_date: 'SGP Roadtax Date',
    puspakom_date: 'Puspakom Date',
    insurance_date: 'Insurance Date',
    service_date: 'Service Date',
    service_due_date: 'Service Due Date',
    remaining_mileage: 'Remaining Mileage',
    service_log: 'Service Log',
    vehicle_log: 'Vehicle Log',
    view_all: 'View All',
    category: 'Category',
    type: 'Type',
    last_service_mileage: 'Last Service Mileage',
    next_date: 'Next Date',
    affordable_mileage: 'Affordable Mileage',
    spare_part_cost: 'Spare Part Cost',
    labour_cost: 'Labour Cost',
    supplier: 'Supplier',
    service: 'Service',
    last_service_date: 'Last Service Date',
    remark: 'Remark',
    service_details: 'Service Details',
    mark_as_done: 'Mark As Done',
    details: 'Details',
    history: 'History',
    categories: 'Categories',
    maintenance_type: 'Maintenance Type',
    date: 'Date',
    spare_cost: 'Spare Cost',
    payment_date: 'Payment Date',
    driver_name: 'Driver Name',
    license_no: 'License No',
    license_expiry_date: 'License Expiry Date',
    psv_expiry_date: 'PSV Expiry Date',
    permit_expiry_date: 'Permit Expiry Date',
    gdl_expiry_date: 'GDL Expiry Date',
    company: 'Company',
    created_by: 'Created by',
    created_at: 'Created At',
    last_updated: 'Last Updated',
    last_updated_at: 'Last Updated at %{time}',
    last_location: 'Last Location',
    check_in_time: 'Check In Time',
    check_out_list: 'Check Out List',
    driver_list: 'Driver List',
    all_driver_list: 'All Driver List',
    driver_type: 'Driver Type',
    check_in_date_time: 'Check In Date / Time',
    check_out_date_time: 'Check Out Date / Time',
    checklist_no: 'Checklist No',
    today_checked_in: 'Today Checked In',
    today_checked_out: 'Today Checked Out',
    checked_in: 'Checked In',
    checked_out: 'Checked Out',
    last_7_days: 'Last 7 days',
    today_total_checklist: 'Today Total Checklist',
    driver_without_checklist: 'Driver Without Checklist',
    vehicle_without_checklist: 'Vehicle Without Checklist',
    failed_item_no: 'Failed Item No',
    verify_by: 'Verify By',
    verify_at: 'Verify At',
    verify: 'Verify',
    driver_claim_overview: 'Driver Claim Overview',
    last_30_days: 'Last 30 days',
    total_claim: 'Total Claim',
    total_petrol_claim: 'Total Petrol Claim',
    toll_claim: 'Toll Claim',
    total_toll_claim: 'Total Toll Claim',
    total_number_of_driver: 'Total Number of Driver',
    petrol_claim: 'Petrol Claim',
    petrol_toll_claim: 'Petrol Toll Claim',
    today: 'Today',
    yesterday: 'Yesterday',
    last_week: 'Last Week',
    last_month: 'Last Month',
    weekly: 'Weekly',
    monthly: 'Monthly',
    yearly: 'Yearly',
    driver_claim_history: 'Driver Claim History',
    toll_claim_rm: 'Toll Claim (RM)',
    petrol_claim_rm: 'Petrol Claim (RM)',
    total_cost_rm: 'Total Cost (RM)',
    driver_claim_profile: 'Driver Claim Profile',
    claim_date: 'Claim Date',
    reviewed_by: 'Reviewed By',
    reviewed_date: 'Reviewed Date',
    attachment: 'Attachment',
    attachments: 'Attachments',
    review: 'Review',
    reject: 'Reject',
    approve: 'Approve',
    point_to_point_distance: 'Point to Point Distance',
    point: 'Point',
    add_point: 'Add Point',
    calculate: 'Calculate',
    duration: 'Duration',
    pto_status: 'PTO Status',
    on: 'On',
    off: 'Off',
    index: 'Index',
    geofence_instance_name: 'Geofence Instance Name',
    number_of_geofence: 'Number Of Geofence',
    creator: 'Creator',
    manage: 'Manage',
    device_integration: 'Device Integration',
    geofence_details: 'Geofence Details',
    trailer_no: 'Trailer No',
    roadtax_expiry_date: 'Roadtax Expiry Date',
    sgp_roadtax_expiry_date: 'SGP Roadtax Expiry Date',
    sgp_expiry_date: 'SGP Expiry Date',
    puspakom_expiry_date: 'Puspakom Expiry Date',
    insurance_start_date: 'Insurance Start Date',
    insurance_expiry_date: 'Insurance Expiry Date',
    pma_expiry_date: 'PMA Expiry Date',
    trailer: 'Trailer',
    trailer_profile: 'Trailer Profile',
    trailer_details: 'Trailer Details',
    roadtax_no: 'Roadtax No',
    singapore_roadtax_no: 'Singapore Roadtax No',
    singapore_roadtax_expiry_date: 'Singapore Roadtax Expiry Date',
    puspakom_no: 'Puspakom No',
    insurance_no: 'Insurance No',
    pma_no: 'PMA No',
    insurance_sum_assured_rm: 'Insurance Sum Assured (RM)',
    assigned_prime_mover: 'Assigned Prime Mover',
    rfid_no: 'RFID No',
    total_active_vehicle: 'Total Active Vehicle',
    total_vehicle: 'Total Vehicle',
    suspicious_activity: 'Suspicious Activity',
    maintenance_notification: 'Maintenance Notification',
    no_of_alert: 'No. of Alert',
    expenses: 'Expenses',
    fuel_usage_mileage: 'Fuel Usage & Mileage',
    last_update_on: 'Last Update On %{date}',
    environment_gas_emission: 'Environment Gas Emission',
    table_of_environment_gas_emission: 'Table Of Environment Gas Emission',
    environment_gas_emission_report: 'Environment Gas Emission Report',
    month: 'Month',
    co2_kg: 'CO2 (kg)',
    ch2_gms: 'CH2 (gms)',
    so2_gms: 'SO2 (gms)',
    no2_gms: 'NOX (gms)',
    pm_gms: 'PM (gms)',
    hc_gms: 'HC (gms)',
    compare_to_last_month: 'compare to last month',
    from_previous_month: 'from previous month',
    total_mileage_km: 'Total Mileage (km)',
    total_travel_time: 'Total Travel Time',
    total_idling_duration: 'Total Idling Duration',
    fuel_economy: 'Fuel Economy',
    chart_of_daily_utilization_of_vehicles: 'Chart Of Daily Utilization Of Vehicles',
    summary_report_list: 'Summary Report List',
    vehicle_report_list: 'Vehicle Report List',
    driver_report_list: 'Driver Report List',
    vehicle_active_report: 'Vehicle Active Report',
    overall_fleet_fuel_avg_economy: 'Overall Fleet Fuel Avg Economy',
    ok: 'Ok',
    offline_for_1_2_days: 'Offline for 1-2 Days',
    offline_for_3_4_days: 'Offline for 3-4 Days',
    'offline_for_5+_days': 'Offline for 5+ Days',
    not_active: 'Not Active',
    'good_0.050km_0.1km_l': 'Good - 0.050km - 0.1km/l',
    'medium_0.050km_0.1km_l': 'Medium - 0.050km - 0.1km/l',
    'alert_0.050km_0.1km_l': 'Alert - 0.050km - 0.1km/l',
    top5_idling_time: 'Top 5 Idling Time',
    top5_high_speed: 'Top 5 High Speed',
    top5_high_fuel_consumption: 'Top 5 High Fuel Consumption',
    top5_fuel_economy: 'Top 5 Fuel Economy',
    driver_score: 'Driver Score',
    average_driver_score: 'Average Driver Score',
    driver_status: 'Driver Status',
    top5_safety_driver: 'Top 5 Safety Drivers',
    top5_high_risk_driver: 'Top 5 High Risk Drivers',
    excellent: 'Excellent',
    fair: 'Fair',
    critical: 'Critical',
    high: 'High',
    distance_km: 'Distance/km',
    active_driver: 'Active Driver',
    event: 'Event',
    no_of_vehicles: 'No. of Vehicles',
    utilized_vehicles: 'Utilized Vehicles',
    kilometer: 'Kilometer',
    liter: 'Liter',
    fuel_usage: 'Fuel Usage',
    mileage: 'Mileage',
    trips: 'Trips',
    vehicle_performance: 'Vehicle Performance',
    driver_app: 'Driver App',
    safety: 'Safety',
    spend: 'Spend',
    operator: 'Operator',
    alert: 'Alert',
    diagnosis: 'Diagnosis',
    all_vehicle_summary_report: 'All Vehicle Summary Report',
    vehicle_status_log: 'Vehicle Status Log',
    vehicle_usage_report: 'Vehicle Usage Report',
    trips_report: 'Trips Report',
    geofence_report: 'Geofence Report',
    poi_report: 'POI Report',
    machinery_summary_report: 'Machinery Summary Report',
    maintenance_log_report: 'Maintenance Log Report',
    idling_summary_report: 'Idling Summary Report',
    raw_data_report: 'Raw Data Report',
    engine_report: 'Engine Report',
    temperature_chart: 'Temperature Chart',
    temperature: 'Temperature',
    pto_camera_report: 'PTO Camera Report',
    rotation_report: 'Rotation Report',
    driving_record_report: 'Driving Record Report',
    trailer_log_report: 'Trailer Log Report',
    trailer_raw_report: 'Trailer Raw Report',
    driver_summary_report: 'Driver Summary Report',
    driver_logs_report: 'Driver Logs Report',
    driver_trips_report: 'Driver Trips Report',
    driver_activity_report: 'Driver Activity Report',
    driver_activity_summary_report: 'Driver Activity Summary Report',
    driver_score_report: 'Driver Score Report',
    driver_safety_report: 'Driver Safety Report',
    driver_safety_behaviour_report: 'Driver Safety Behaviour Report',
    driver_job_report: 'Driver Job Report',
    get_checklist_report: 'Get Checklist Report',
    vehicle_safety_report: 'Vehicle Safety Report',
    fatigue_photo_video_report: 'Fatigue Photo Video Report',
    fatigue_monitor_board: 'Fatigue Monitor Board',
    fatigue_driving_record: 'Fatigue Driving Record',
    toll_report: 'Toll Report',
    fuel_drop_report: 'Fuel Drop Report',
    refuel_summary_report: 'Refuel Summary Report',
    mileage_liter_by_statement: 'Mileage Liter By Statement',
    operator_company_daily_report: 'Operator Company Daily Report',
    operator_attendance_report: 'Operator Attendance Report',
    operator_driver_report: 'Operator Driver Report',
    suspicious_activity_report: 'Suspicious Activity Report',
    critical_alert_report: 'Critical Alert Report',
    alert_report: 'Alert Report',
    maintenance_alert_report: 'Maintenance ALert Report',
    vehicle_diagnosis_report: 'Vehicle Diagnosis Report',
    speed_report: 'Speed Report',
    overspeed_record_report: 'Overspeed Record Report',
    report: 'Report',
    yes: 'Yes',
    no: 'No',
    item_for_inspection: 'Item For Inspection',
    minyak_power_setting: 'Minyak Power Setting',
    recently_viewed: 'Recently Viewed',
    starred: 'Starred',
    idling_time_min: 'Idling Time (Min)',
    report_type: 'Report Type',
    customize_column: 'Customize Column',
    fleet: 'Fleet',
    max_speed_km_h: 'Max Speed (KM/H)',
    fuel_usage_l: 'Fuel Usage (L)',
    fuel_cost_rm: 'Fuel Cost (RM)',
    refuel_amount_l: 'Refuel Amount (L)',
    km_l: 'KM/L',
    fuel_efficiency_perc: 'Fuel Efficiency (%)',
    fuel_consumption_km_l: 'Fuel Consumption (KM/L)',
    total_idle_duration: 'Total Idle Duration',
    unallowed_idle_duration: 'Unallowed Idle Duration',
    idling_fuel_usage_l: 'Idling Fuel Usage (L)',
    idle_cost_rm: 'Idle Cost (RM)',
    total_driving_duration: 'Total Driving Duration',
    total_journey_time: 'Total Journey Time',
    avg_daily_speed_km_h: 'Avg. Daily Speed (KM/H)',
    data_summary: 'Data Summary',
    data_details: 'Data Details',
    profile_info: 'Profile Info',
    training_record: 'Training Record',
    violation_record: 'Violation Record',
    driver_detail: 'Driver Detail',
    driver_details: 'Driver Details',
    staff_id: 'Staff ID',
    tel_no_hp: 'Tel No. (hp)',
    date_of_birth: 'Date Of Birth',
    emergency_contact: 'Emergency Contact',
    nric: 'NRIC',
    email: 'Email',
    gender: 'Gender',
    password: 'Password',
    nationality: 'Nationality',
    rental_fee: 'Rental Fee',
    other_information: 'Other Information',
    other_informations: 'Other Informations',
    driving_license_no: 'Driving License No',
    driving_license_expiry_date: 'Driving License Expiry Date',
    commercial_license_no_gdl: 'Commercial License No (GDL)',
    comm_license_no_expiry_date: 'Comm. License No Expiry Date',
    permit_no: 'Permit No',
    psv_no: 'PSV No',
    psv_license_expire: 'PSV License Expire',
    hired_date: 'Hired Date',
    salary: 'Salary',
    bank_acc: 'Bank Acc',
    bank_acc_no: 'Bank Acc No',
    assign_to: 'Assign To',
    employment_status: 'Employment Status',
    permanent: 'Permanent',
    note: 'Note',
    ic_license_upload: 'IC & License Upload',
    ic: 'IC',
    driving_license: 'Driving License',
    comm_license_gdl: 'Comm. License (GDL)',
    training_title: 'Training Title',
    attended_date: 'Attended Date',
    expiry_date: 'Expiry Date',
    cost: 'Cost',
    cost_rm: 'Cost (RM)',
    compulsory: 'Compulsory',
    violation_type: 'Violation Type',
    violation_date: 'Violation Date',
    fine: 'Fine',
    fine_rm: 'Fine (RM)',
    pay_by: 'Pay By',
    other_details: 'Other Details',
    driver_apps_credential: 'Driver Apps Credential',
    pick_your_birthday: 'Pick Your Birthday',
    male: 'Male',
    female: 'Female',
    next: 'Next',
    malaysian: 'Malaysian',
    non_malaysian: 'Non Malaysian',
    direct_driver: 'Driver Driver',
    feeder_driver: 'Feeder Driver',
    comm_license: 'Comm. License',
    please_ignore_it_if_not_applicable: '* Please Ignore It If Not Applicable',
    username: 'Username',
    assign_to_supervisor: 'Assign To Supervisor',
    supervisor: 'Supervisor',
    route_group: 'Route Group',
    route_in_charge: 'Route In Charge',
    trip_details: 'Trip Details',
    historical_playback: 'Historical Playback',
    alert_log: 'Alert Log',
    avg_speed: 'Avg Speed',
    activities: 'Activities',
    driver_distance: 'Driver Distance',
    violation: 'Violation',
    device_type: 'Device Type',
    color: 'Color',
    manufacturer_model: 'Manufacturer Model',
    chasis_no: 'Chasis No',
    owned_sub_con: 'Owned/Sub-Con',
    capacity: 'Capacity',
    capacity_unit: 'Capacity Unit',
    tng_no: 'TNG No',
    fleet_card_no: 'Fleet Card No',
    tank_no: 'Tank No',
    electrical_system: 'Electrical System',
    vehicle_details: 'Vehicle Details',
    vehicle_industry: 'Vehicle Industry',
    speed_limit_km_h: 'Speed Limit (km/h)',
    allowing_idling_time: 'Allowing Idling Time',
    operation_hour_per_week_hrs: 'Operation Hour Per Week (hrs)',
    main_road_condition: 'Main Road Condition',
    good_type: 'Good Type',
    estimated_overload_perc: 'Estimated Overload (%)',
    idling_alert_setting: 'Idling Alert Setting',
    idling_minutes: 'Idling Minutes',
    please_leave_blank_for_using_the_default_idling_setting: '* Please leave blank for using the default idling setting',
    minutes: 'Minutes',
    low_fuel_alert_setting: 'Low Fuel Alert Setting',
    low_fuel_percentage: 'Low Fuel Percentage',
    please_leave_blank_for_using_the_default_low_fuel_setting: '* Please leave blank for using the default low fuel setting',
    low_fuel_critical_alert: 'Low Fuel Critical Alert',
    fuel_drop_percentage: 'Fuel Drop Percentage',
    please_leave_blank_for_using_the_default_fuel_drop_setting: '* Please leave blank for using the default fuel drop setting',
    please_leave_blank_for_using_the_default_fuel_efficiency: '* Please leave blank for using the default fuel efficiency',
    please_leave_blank_for_using_the_default_fuel_waste_rate: '* Please leave blank for using the default fuel waste rate',
    please_leave_blank_for_using_the_default_minimum_refuel_detection: '* Please leave blank for using the default minimum refuel detection',
    prohibit_driving_periods: 'Prohibit Driving Periods',
    add_new_prohibit_period: 'Add New Prohibit Period',
    remove_all: 'Remove All',
    document_due_date_reminder: 'Document Due Date Reminder',
    reminder_date: 'Reminder Date',
    vehicle_documents: 'Vehicle Documents',
    registration_certificate: 'Registration Certificate',
    fuel_history: 'Fuel History',
    today_active_inactive_vehicle: 'Today Active & Inactive Vehicle',
    distance: 'Distance',
    maintenance_alert_reminder: 'Maintenance Alert / Reminder',
    past_checklist: 'Past Checklist',
    today_checklist: 'Today Checklist',
    section: 'Section',
    na: 'N/A',
    click_to_upload: 'Click To Upload',
    vehicle_profile: 'Vehicle Profile',
    vehicle_report: 'Vehicle Report',
    chassis_no: 'Chassis No',
    fuel_rate: 'Fuel Rate',
    toll_class: 'Toll Class',
    oil_volume: 'Oil Volume',
    tyre_management: 'Tyre Management',
    qr_code: 'QR Code',
    certificate: 'Certificate',
    oil_measurement_setting: 'Oil Measurement Setting',
    total_fuel_usage: 'Total Fuel Usage',
    average_fuel_consumption: 'Average Fuel Consumption',
    tyres_healthy_level: 'Tyres Healthy Level',
    vehicle_utilization: 'Vehicle Utilization',
    vehicle_safety_level: 'Vehicle Safety Level',
    cost_per_km: 'Cost Per Km',
    cpk_value_rm: 'CPK Value (RM)',
    upcoming: 'Upcoming',
    overdue: 'Overdue',
    group_average_fuel_performance: 'Group Average Fuel Performance',
    ltr_fuel_waste_when_idling: '%{val} ltr fuel waste when idling',
    engine: 'Engine',
    next_service_date: 'Next Service Date',
    remaining_km: 'Remaining km',
    next_km: 'Next km',
    last_km: 'Last km',
    tyre_maintenance_log: 'Tyre Maintenance Log',
    updated_at: 'Updated At',
    updated_by: 'Updated By',
    view_detail: 'View Detail',
    installed_date: 'Installed Date',
    tread_depth: 'Tread Depth',
    sn: 'S/N',
    tyre_pressure: 'Tyre Pressure',
    brand: 'Brand',
    used_km: 'Used km',
    model: 'Model',
    estimated_remaining_km: 'Estimated Remaining km',
    size: 'Size',
    cooling_system: 'Cooling System',
    transmission_gear_box: 'Transmission/Gear Box',
    braking: 'Braking',
    body: 'Body',
    other: 'Other',
    add_new_record: 'Add New Record',
    vehicle_kpi: 'Vehicle KPI',
    permit: 'Permit',
    roadtax: 'Roadtax',
    sgp_roadtax: 'SGP Roadtax',
    insurance: 'Insurance',
    expired_soon: 'Expired Soon',
    expired: 'Expired',
    expired_date: 'Expired Date',
    add_new_instance: 'Add New Instance',
    geofence_list: 'Geofence List',
    integration_type: 'Integration Type',
    add_geofence: 'Add Geofence',
    add_new_geofence: 'Add New Geofence',
    edit_geofence: 'Edit Geofence',
    zone_type: 'Zone Type',
    mode: 'Mode',
    overtime_minute: 'Overtime (Minute)',
    check_point: 'Check Point',
    check_point_without_notification: 'Check Point Without Notification',
    prohibited: 'Prohibited',
    in_region: 'In Region',
    out_region: 'Out Region',
    in_out_region: 'In/Out Region',
    events: 'Events',
    driving_distance: 'Driving Distance',
    remaining: 'Remaining',
    days: 'Days',
    day: 'Day',
    documentation_log: 'Documentation Log',
    documentation: 'Documentation',
    machinery_tracking: 'Machinery Tracking',
    safety_equipment: 'Safety Equipment',
    equipment: 'Equipment',
    update_documentation_log: 'Update Documentation Log',
    update_machinery_tracking: 'Update Machinery Tracking',
    update_equipment: 'Update Equipment',
    update_other: 'Update Other',
    update_safety_equipment: 'Update Safety Equipment',
    engine_on_date: 'Engine On Date',
    engine_on_remaining_duration_hours: 'Engine On Remaining Duration (Hours)',
    fire_extinguisher_expiry_date: 'Fire Extinguisher Expiry Date',
    tanker_calibration_expiry_date: 'Tanker Calibration Expiry Date',
    permit_no_expiry_date: 'Permit No (Expiry Date)',
    roadtax_no_expiry_date: 'Roadtax No (Expiry Date)',
    singapore_roadtax_no_expiry_date: 'Singapore Roadtax No (Expiry Date)',
    puspakom_receipt_no_expiry_date: 'Puspakom Receipt No (Expiry Date)',
    insurance_sum_assured: 'Insurance Sum Assured',
    upload_by_time: 'Uploaded by %{name} at %{time}',
    fuel_price: 'Fuel Price',
    default_price_rm: 'Default Price (RM)',
    default: 'Default',
    default_rate: 'Default Rate',
    enter_rate: 'Enter Rate',
    predefine: 'Predefine',
    please_enter_code: 'Please Enter Code',
    please_enter_description: 'Please Enter Description',
    please_enter_default_rate: "Please Enter Default Rate",
    please_enter_correct_rate: "Please Enter Correct Rate",
    please_complete_all_rate_details: "Please Complete All Rate Details",
    create_fuel_price: "Create Fuel Price",
    update_fuel_price: "Update Fuel Price",
    delete_fuel_price: "Delete Fuel Price",
    success: "Success",
    error: "Error",
    create_maintenance_category: "Create Maintenance Category",
    update_maintenance_category: "Update Maintenance Category",
    delete_maintenance_category: 'Delete Maintenance Category',
    speed_limit: "Speed Limit",
    max_idling_duration: "Max Idling Duration",
    operation_hour_per_week: "Operation Hour Per Week",
    goods_type: "Goods Type",
    estimated_overload: "Estimated Overload",
    km: 'km',
    km_h: 'km/h',
    mins: 'mins',
    hrs: 'hrs',
    perc: '%',
    kg: 'kg',
    lbs: 'lbs',
    l_100km: 'ℓ/100km',
    hour_s: 'Hour(s)',
    l_idling_min: 'ℓ/idling minute',
    l: 'ℓ',
    please_enter_speed_limit: "Please Enter Speed Limit",
    please_enter_max_idling_duration: "Please Enter Max Idling Duration",
    please_enter_operation_hour_per_week: "Please Enter Operation Hour Per Week",
    please_enter_estimated_overload: "Please Enter Estimated Overload",
    please_select_road_condition: "Please Select Road Condition",
    please_enter_goods_type: "Please Enter Goods Type",
    general_road: "General Road",
    highway: "Highway",
    fleet_group_name: "Fleet Group Name",
    fleet_group: "Fleet Group",
    fleet_group_details: "Fleet Group Details",
    add_emergency_contact: "Add Emergency Contact",
    emergency_contact_person: "Emergency Contact Person",
    emergency_contact_number: "Emergency Contact Number",
    emergency_response_plan_setting: "Emergency Response Plan Setting",
    erp_contact_person: "ERP Contact Person",
    erp_contact_no: "ERP Contact Number",
    please_enter_fleet_group_name: "Please Enter Fleet Group Name",
    please_enter_contact_person_name: "Please Enter Contact Person Name",
    please_enter_contact_number: "Please Enter Contact Number",
    please_enter_correct_contact_number: "Please Enter Correct Contact Number",
    please_complete_emergency_contact_details: "Please Complete Emergency Contact Details",
    please_enter_correct_erp_contact_number: "Please Enter Correct ERP Contact Number",
    basic_information: "Basic Information",
    privacy_information: "Privacy Information",
    advance_information: "Advance Information",
    geotab_integration: "Geotab Integration",
    geotab_server: "Geotab Server",
    geotab_database: "Geotab Database",
    geotab_username: "Geotab Username",
    geotab_password: "Geotab Password",
    apad_gateway_integration: "Apad Gateway Integration",
    api_key: "API Key",
    api_password: "API Password",
    sovy_driver_preinspection_check_integration: "Sovy Driver Preinspection Check Integration",
    sovy_username: "Sovy Username",
    sovy_password: "Sovy Password",
    api_link: "API Link",
    company_id: "Company ID",
    branch_id: "Branch ID",
    idle_setting: "Idle Setting",
    vehicle_listing: "Vehicle Listing",
    enter_vehicle: "Enter Vehicle",
    selected_vehicle: "Selected Vehicle",
    no_vehicle: "No Vehicle",
    new_vehicle: "New Vehicle",
    everyday: "Everyday",
    weekday: "Weekday",
    weekend: "Weekend",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    update_service_log: "Update Service Log",
    create_service_log: "Create Service Log",
    delete_maintenance: "Delete Maintenance",
    create_fleet_group: "Create Fleet Group",
    update_fleet_group: "Update Fleet Group",
    delete_fleet_group: "Delete Fleet Group",
    tracker_details: "Tracker Details",
    tracker: "Tracker",
    new_tracker: "New Tracker",
    please_select_tracker: "Please Select Tracker",
    class_1_toll: 'CLASS 1 (Vehicles with 2 axies and 3 or 4 wheels excluding taxis)',
    class_2_toll: 'CLASS 2 (Vehicles with 2 axies and 5 or 6 wheels excluding buses)',
    class_3_toll: 'CLASS 3 (Vehicles with 3 or more axies)',
    class_4_toll: 'CLASS 4 (Taxi)',
    class_5_toll: 'CLASS 5 (Bus)',
    class_1_penang_toll: 'CLASS 1 (Motorcycles)',
    class_2_penang_toll: 'CLASS 2 (Commercial vehicle having 3 wheels and Car inclusive station wago)',
    class_3_penang_toll: 'CLASS 3 (Lorry and van having 2 axies and 4 wheels and Bus having 2 axies and 4 wheels)',
    class_4_penang_toll: 'CLASS 4 (Lorry and van having 2 axies and 5 or 6 wheels and Bus having 2 axies and 5 or 6 wheels)',
    class_5_penang_toll: 'CLASS 5 (Vehicles / Bus having 3 axies)',
    class_6_penang_toll: 'CLASS 6 (Vehicles having 4 axies)',
    class_7_penang_toll: 'CLASS 7 (Vehicles having 5 axies or more)',
    tyre_setting: 'Tyre Setting',
    tyre_maintenance: 'Tyre Maintenance',
    esg_setting: 'ESG Setting',
    esg_module: 'ESG Module',
    fuel_brenchmark_setting: 'Fuel Brenchmark Setting',
    fuel_efficiency: "Fuel Efficiency",
    fuel_wastage_rate: "Fuel Wastage Rate",
    toll_setting: "Toll Setting",
    penang_bridge_class: "Penang Bridge Class",
    mpob_integration: "MPOB Integration",
    mpob_device_id: "MPOB Device Id",
    cargomove_integration: 'CargoMove Integration',
    cargomove_plate_no: 'CargoMove Plate No',
    lpj_integration: 'LPJ Integration',
    lpj_plate_no: 'LPJ Plate No',
    upload: 'Upload',
    reupload: 'Reupload',
    download: 'Download',
    are_you_sure_you_want_to_upload: 'Are you sure you want to upload: ',
    tank_info: 'Tank Info',
    driver_reference: 'Driver Reference',
    region: 'Region',
    transport_type: 'Transport Type',
    display_details: 'Display Details',
    vehicle_icon: 'Vehicle Icon',
    marker_image: 'Marker Image',
    oil_setting: 'Oil Setting',
    fuel_price_selection: 'Fuel Price Selection',
    engine_hour_setting: 'Engine Hour Setting',
    engine_hour_meter: 'Engine Hour Meter',
    system_calculated_engine_hour: 'System Calculated Engine Hour',
    adjust_to_engine_hour: 'Adjust To Engine Hour',
    last_engine_hour_adjustment: 'Last Engine Hour Adjustment',
    please_note_that_any_adjustment_will_calculated_when_its_synced_engine_hour_is_available: "* Please note that any adjustment will calculated when it's synced engine hour is available",
    'set_adjust_to_engine_hour_to_-1_for_remove_the_adjustment': '* Set adjust to engine hour to -1 for remove the adjustment',
    pto_critical_alert: 'PTO Critical Alert',
    pto_display: 'PTO Display',
    alert_triggered: 'Alert Triggered',
    when_pto_is_on: 'When PTO is ON',
    when_pto_is_off: 'When PTO is OFF',
    never_trigger: 'Never Trigger',
    upload_profile_image: 'Upload Profile Image',
    confirm_upload: 'Confirm Upload',
    billing_entity_name: 'Billing Entity Name',
    billing_entity: 'Billing Entity',
    vehicle_document: 'Vehicle Document',
    voltage: 'Voltage',
    create_vehicle: 'Create Vehicle',
    update_vehicle: 'Update Vehicle',
    IDLING: 'Idling',
    DRIVING: 'Driving',
    PARKING: 'Parking',
    OFFLINE: 'Offline',
    battery: 'Battery',
    satellite: 'Satellite',
    antenna: 'Antenna',
    odometer: 'Odometer',
    fuel_details: 'Fuel Details',
    device_model: 'Device Model',
    terminal_id: 'Terminal ID',
    timezone: 'Timezone',
    fuel_enable: 'Fuel Enable',
    device_mode: 'Device Mode',
    rotation_sensor_setting: 'Rotation Sensor Setting',
    rotation_sensor: 'Rotation Sensor',
    temperature_sensor_setting: 'Temperature Sensor Setting',
    temperature_sensor: 'Temperature Sensor',
    fuel_sensor_setting: 'Fuel Sensor Setting',
    fuel_sensor: 'Fuel Sensor',
    minimum_refuel_detection: 'Minimum Refuel Detection',
    minimum_tank_lvl_oil_resistance: 'Minimum Tank Level (%) / Oil Resistance (ohm)',
    maximum_tank_lvl_oil_resistance: 'Maximum Tank Level (%) / Oil Resistance (ohm)',
    fuel_tap_sensor: 'Fuel Tap Sensor',
    reverse_ohm: 'Reverse (ohm)',
    sensor_type: 'Sensor Type',
    tank_volume: 'Tank Volume',
    general: 'General',
    reverse: 'Reverse',
    create_tracker: 'Create Tracker',
    update_tracker: 'Update Tracker',
    please_enter_sim_card_no: 'Please enter sim card no',
    please_select_device_mode: 'Please select device mode',
    please_enter_device_id: 'Please enter device id',
    please_select_device_type: 'Please select device type',
    medical_expiry_date: 'Medical Expiry Date',
    create_driver: 'Create Driver',
    update_driver: 'Update Driver',
    resigned: 'Resigned',
    suspended: 'Suspended',
    employed: 'Employed',
    please_enter_driver_name: 'Please Enter Driver Name',
    please_select_employment_status: 'Please Select Employment Status',
    delete_driver: 'Delete Driver',
    vehicle_status: 'Vehicle Status',
    update_vehicle_status: 'Update Vehicle Status',
    generating_certificate: 'Generating Certificate',
    please_wait_while_we_download_your_file_this_may_take_awhile_thanks: 'Please wait while we download your file this may take a while, thanks',
    map_instance: 'Map Instance',
    owner: 'Owner',
    only_shown_account_enable: 'Only Shown Account Enable',
    user: 'User',
    integration_list: 'Integration List',
    online_status: 'Online Status',
    movement_status: 'Movement Status',
    please_enter_map_instance_name: 'Please Enter Map Instance Name',
    please_select_integration_type: 'Please Select Integration Type',
    please_select_owner: 'Please Select Owner',
    please_select_only_shown_account_enable: 'Please Select Only Shown Account Enable',
    please_select_integration_list: 'Please Select Integration List',
    profile: 'Profile',
    device_settings: 'Device Settings',
    user_settings: 'User Settings',
    manage_temporary_view: 'Manage Temporary View',
    company_rules_complaints: 'Company Rules & Complaints',
    help_center: 'Help Center',
    edit_password: 'Edit Password',
    company_logo: 'Company Logo',
    current_password: 'Current Password',
    new_password: 'New Password',
    confirm_password: 'Confirm Password',
    please_enter_current_password: 'Please Enter Current Password',
    please_enter_new_password: 'Please Enter New Password',
    please_enter_confirm_password: 'Please Enter Confirm Password',
    password_not_match: 'Password Not Match',
    faqs: 'FAQs',
    frequently_asked_questions: 'Frequently Asked Questions',
    keyword: 'Keyword',
    focus_on: 'Focus On',
    driving_duration: 'Driving Duration',
    delete_map_instance: 'Delete Map Instance',
    focus_vehicle: 'Focus Vehicle',
    driving: 'Driving',
    fill_color: 'Fill Color',
    stroke_color: 'Stroke Color',
    delete_geofence: 'Delete Geofence',
    create_geofence: 'Create Geofence',
    update_geofence: 'Update Geofence',
    new_map_instance: 'New Map Instance',
    create_poi: 'Create POI',
    update_poi: 'Update POI',
    delete_poi: 'Delete POI',
    meter: 'Meter',
    please_enter_poi_name: 'Please Enter POI Name',
    please_enter_poi_latitude: 'Please Enter POI Latitude', 
    please_enter_poi_longitude: 'Please Enter POI Longitude',
    please_select_poi_icon: 'Please Select POI Icon',
    please_enter_poi_radius: 'Please Enter POI Radius',
    firmware_management: 'Firmware Management',
    geofence_settings: 'Geofence Settings',
    alarm_configuration_vehicle_control: 'Alarm Configuration / Vehicle Control',
    please_select_vehicle_to_set_alert_configurations_controls: 'Please Select Vehicle(s) to Set Alert Configurations / Controls',
    enter_keyword_to_search: 'Enter Keyword to Search',
    device_settings_mode: 'Device Settings Mode',
    upload_firmware: 'Upload Firmware',
    file_name: 'File Name',
    firmware_upload: 'Firmware Upload',
    click_here_to_upload_or_drop_your_files_here: 'Click here to upload or drop your files here',
    file: 'File',
    please_upload_firmware_file: 'Please Upload Firmware File',
    create_device_firmware: 'Create Device Firmware',
    update_device_firmware: 'Update Device Firmware',
    proceed: 'Proceed',
    fleet_list: 'Fleet List',
    selected_fleet: 'Selected Fleet',
    deselect: 'Deselect',
    select: 'Select',
    vehicle_list: 'Vehicle List',
    hint: 'Hint',
    device_firmware: 'Device Firmware',
    send: 'Send',
    please_select_device_firmware: 'Please Select Device Firmware',
    is_required: 'Is Required',
    wrong_format: 'Wrong Format',
    must_be_a_number: 'Must be a number',
    immobilizer_password: 'Immobilizer Password',
    please_enter_immobilizer_password: 'Please Enter Immobilizer Password',
    alarm_configuration_vehicle_control_history: 'Alarm Configuration / Vehicle Control History',
    alarm_configuration_vehicle_control_records: 'Alarm Configuration / Vehicle Control Records',
    cancelled: 'Cancelled',
    sent: 'Sent',
    settings_information: 'Settings Information',
    settings: 'Settings',
    value: 'Value',
    return_raw: 'Return Raw',
    return_value: 'Return Value',
    resend: 'Resend',
    refresh: 'Refresh',
    cancel_all_command: 'Cancel All Command',
    resend_all_command: 'Resend All Command',
    account_type: 'Account Type',
    new_user: 'New User',
    user_details: 'User Details',
    system_setting: 'System Setting',
    email_reporting_extra_modules: 'Email Reporting / Extra Modules',
    fleet_group_listing: 'Fleet Group Listing',
    weekly_overspeed_mail_enable: 'Weekly Overspeed Mail Enable',
    weekly_summary_mail_enable: 'Weekly Summary Mail Enable',
    daily_summary_mail_enable: 'Daily Summary Mail Enable',
    daily_maintenance_mail_enable: 'Daily Maintenance Mail Enable',
    admin: 'Admin',
    main_account: 'Main Account',
    sub_account: 'Sub Account',
    notification_email: 'Notification Email',
    add_notification_email: 'Add Notification Email',
    tracking_page_data_interval_in_second: 'Tracking Page Data Interval In Second',
    suggested_value: 'Suggested Value',
    currency_display: 'Currency Display',
    initial_map_location: 'Initial Map Location',
    critical_alert_filter: 'Critical Alert Filter',
    select_fleet_group: 'Select Fleet Group',
    no_fleet_group: 'No Fleet Group',
    selected_fleet_group: 'Selected Fleet Group',
    new_fleet_group: 'New Fleet Group',
    new_company: 'New Company',
    company_name: 'Company Name',
    company_registration_no: 'Company Registration No',
    company_contact_person: 'Company Contact Person',
    company_contact_no: 'Company Contact No',
    company_details: 'Company Details',
    company_address: 'Company Address',
    user_listing: 'User Listing',
    select_user: 'Select User',
    selected_user: 'Selected User',
    no_user: 'No User',
    detail: 'Detail',
    role: 'Role',
    new_role: 'New Role',
    roles_details: 'Roles Details',
    role_name: 'Role Name',
    features_enabled: 'Features Enabled',
    modules: 'Modules',
    features: 'Features',
    get: 'Get',
    update: 'Update',
    create: 'Create',
    refuse_all: 'Refuse All',
    grant_all: 'Grant All',
    select_module_first: 'Select Module First',
    new_setting: 'New Setting',
    setting_name: 'Setting Name',
    using_users_fleet: 'Using Users Fleet',
    email_notification_setting_details: 'Email Notification Setting Details',
    notify_email_entry: 'Notify Email Entry',
    geofence_instance_filter: 'Geofence Instance Filter',
    notification_enabled: 'Notification Enabled',
    nearby: 'Nearby',
    checklist_profile: 'Checklist Profile',
    total_fuel_spend: 'Total Fuel Spend',
    total_distance: 'Total Distance',
    score: 'Score',
    copy_vehicle_info: 'Copy vehicle info to clipboard successfully',
    shortcuts: 'Shortcuts',
    starred_your_favourites_to_quick_access: 'Starred your favourites to quick access',
    roadtax_attachment: 'Roadtax Attachment',
    permit_attachment: 'Permit Attachment',
    notifications: 'Notifications',
    view_all_notifications: 'View All Notifications',
    critical_alert: 'Critical Alert',
    notification: 'Notification',
    driving_behaviour: 'Driving Behaviour',
    grid_of: 'Grid of %{column}',
    columns: 'Columns',
    spotlight: 'Spotlight',
    no_data_available: 'No Data Available',
    no_notification: 'No Notification',
    please_select_fleet_group_or_vehicle_first: 'Please select fleet group or vehicle first',
    enter_plate_no: 'Enter Plate No',
    select_type: 'Select Type',
    please_complete_all_required_fields: 'Please Complete All Required Fields',
    please_enter_email: 'Please Enter Email',
    please_select_account_type: 'Please Select Account Type',
    please_select_status: 'Please Select Status',
    template_name: 'Template Name',
    company_checklist: 'Company Checklist',
    checklist: 'Checklist',
    please_select_checklist: 'Please Select Checklist',
    please_enter_company_name: 'Please Enter Company Name',
    new_checklist: 'New Checklist',
    checklist_details: 'Checklist Details',
    select_a_company: 'Select a Company',
    checklist_name: 'Checklist Name',
    checklist_item_name: 'Checklist Item Name',
    enable_remark: 'Enable Remark',
    enable_attachment: 'Enable Attachment',
    add_new_checklist_item: 'Add New Checklist Item',
    checklist_items: 'Checklist Items',
    select_a_workshop: 'Select a Workshop',
    rating_date: 'Rating Date',
    workshop_name: 'Workshop Name',
    select_a_status: 'Select a Status',
    please_enter_city: 'Please Enter City',
    please_enter_contact_no: 'Please Enter Contact No',
    new_breakdown_support: 'New Breakdown Support',
    city: 'City',
    contact_person: 'Contact Person',
    latitude: 'Latitude',
    longitude: 'Longitude',
    pos_code: 'Pos Code',
    speciality: 'Speciality',
    breakdown: 'Breakdown',
    smart_fleet_analytics_dashboard: 'Smart Fleet Analytics Dashboard',
    revenue_analysis_report: 'Revenue Analysis Report',
    total_expenses: 'Total Expenses',
    total_earning: 'Total Earning',
    current_total_earning: 'Current Total Earning',
    cost_trend_report: 'Cost Trend Report',
    total_trip_cost: 'Total Trip Cost',
    total_maintenance_cost: 'Total Maintenance Cost',
    average_monthly_cost: 'Average Monthly Cost',
    trip_cost: 'Trip Cost',
    maintenance_cost: 'Maintenance Cost',
    trip_cost_breakdown: 'Trip Cost Breakdown',
    fuel_purchased: 'Fuel Purchased',
    toll_cost: 'Toll Cost',
    driver_expense: 'Driver Expense',
    add_report_to_starred_success: 'Add report to starred success',
    remove_report_from_starred_success: 'Remove report from starred success',
    remove_shortcut_success: 'Remove shortcut success',
    add_shortcut_success: 'Add shortcut success',
    maintenance_cost_breakdown: 'Maintenance Cost Breakdown',
    expense: 'Expense',
    earning: 'Earning',
    top5_high_maintenance_cost: 'Top 5 High Maintenance Cost',
    fuel_performance_trend_report_for_industry_profile: 'Fuel Performance Trend Report for Industry Profile',
    operation_performance: 'Operation Performance',
    total_fuel_cost: 'Total Fuel Cost',
    est_idling_cost: 'Est Idling Cost',
    industry_profile_efficiency_target: 'Industry Profile Efficiency Target',
    industry_profile_efficiency_baseline: 'Industry Profile Efficiency Baseline',
    fuel_consumption_auto_refuel: 'Fuel Consumption (Auto Refuel)',
    fuel_purchase_refuel: 'Fuel Purchase (Refuel)',
    utilization_rate: 'Utilization Rate',
    fuel_performance: 'Fuel Performance',
    revenue_rm: 'Revenue (RM)',
    operational_performance_rm_km: 'Operational Performance (RM/km)',
    average_per_vehicle: 'Average Per Vehicle',
    this_month: 'This Month',
    target: 'Target',
    total_cost_breakdown: 'Total Cost Breakdown',
    top5_maintenance_breakdown: 'Top 5 Maintenance Breakdown',
    fuel_liter: 'Fuel (Liter)',
    total_vehicles: 'Total Vehicles',
    today_active: 'Today Active',
    today_inactive: 'Today Inactive',
    average_driver_score_speed: 'Average Driver Score & Speed',
    speed_km_h: 'Speed (km/h)',
    average_idling_rate_per_total_driving_time: 'Average Idling Rate Per Total Driving Time',
    average_speeding_rate_per_total_driving_time: 'Average Speeding Rate Per Total Driving Time',
    average_harsh_event_per_total_driving_time: 'Average Harsh Event Per Total Driving Time',
    top5_high_idling_event: 'Top 5 High Idling Event',
    top5_high_speeding_event: 'Top 5 High Speeding Event',
    top5_high_harsh_event: 'Top 5 High Harsh Event',
    fleet_idling_rate_trend: 'Fleet Idling Rate Trend',
    fleet_speeding_rate_trend: 'Fleet Speeding Rate Trend',
    fleet_harsh_event_trend: 'Fleet Harsh Event Trend',
    idling_of_total_drive_time_percentage: 'Idling Of Total Drive Time Percentage',
    speeding_of_total_drive_time_percentage: 'Speeding Of Total Drive Time Percentage',
    harsh_event_per_1000_km: 'Harsh Event Per 1000 km',
    score_distribution: 'Score Distribution',
    poor: 'Poor',
    meet_goals: 'Meet Goals',
    top_performance: 'Top Performance',
    risk_factors_speeding: 'Risk Factors - Speeding',
    tag: 'TAG',
    count: 'COUNT',
    rate_or_percentage: 'RATE or %',
    score_impact: 'SCORE IMPACT',
    driver_count: 'Driver Count',
    vehicle_history: 'Vehicle History',
    fuel_setting_history: 'Fuel Setting History',
    new_record: 'New Record',
    vehicle_record: 'Vehicle Record',
    efficient_date: 'Efficient Date',
    delete_vehicle_record: 'Delete Vehicle Record',
    delete_fuel_setting_record: 'Delete Fuel Setting Record',
    fuel_setting_record: 'Fuel Setting Record',
    vehicle_setting: 'Vehicle Setting',
    request_ip: 'Request IP',
    request_url: 'Request URL',
    enter_keyword: 'Enter Keyword',
    please_enter_keyword: 'Please Enter Keyword',
    please_select_mode: 'Please Select Mode',
    please_select_object_type: 'Please Select Object Type',
    please_select_user: 'Please Select User',
    insert: 'Insert',
    tollName:'Toll Name',
    groupName:'Group Name',
    tollGroupName: 'Toll Group Name',
    rateType: 'Rate Type',
    new_toll: 'New Toll',
    toll_point_panels: 'Toll Point Panels',
    create_a_toll_line:'Create a toll line',
    There_is_no_toll_line: 'There has no any toll line yet',
    enter_name: 'Enter Name',
    select_point_type: 'Select Point Type',
    entry: 'Entry',
    exit: 'Exit',
    toll_list:'Toll List',
    selected_toll_list:'Selected Toll List',
    edit_toll_list: 'Edit Toll List',
    add_list: 'Add',
    remove_list: 'Remove',
    enter_group_name: 'Enter Group Name',
    group_name_is_required: 'Group Name is Required',
    tolls: 'Tolls',
    single: 'Single',
    multi: 'Multi',
    price_monthly: 'Price (monthly)',
    go_to_market_place: 'Go To Market Place',
    insurance_details: 'Insurance Details',
    price: 'Price',
    claim: 'Claim',
    claim_history: 'Claim History',
    renew: 'Renew',
    select_a_driver: 'Select a Driver',
    severity: 'Severity',
    avg_exceed: 'AVG Exceeded',
    avg_speed_limit: 'AVG. Speed/speed limit',
    time_and_location: 'Time & Location',
    select_a_route: 'Select a Route',
    new_route: 'New Route',
    new_route_group: 'New Route Group',
    route: 'Route',
    route_type: 'Route Type',
    route_details: 'Route Details',
    select_group_name: 'Select Group Name',
    select_a_route_type: 'Select a route type',
    select_a_route_group: 'Select a route group',
    select_a_code: 'Select a code',
    type_route_name_here: 'Type route name here',
    type_route_code_here: 'Type route code here',
    submit: 'Submit',
    map_details: 'Map Details',
    assign_by_operator: 'Assign By Operator',
    route_group_details: 'Route Group Details',
    shift_details: 'Shift Details',
    add_shift: 'Add Shift',
    shift_code: 'Shift Code',
    shift: 'Shift',
    assign: 'Assign',
    current_route_group: 'Current Route Group',
    job_type: 'Job Type',
    phone: 'Phone',
    pic: 'PIC',
    sequence: 'Sequence',
    edit_route: 'Edit Route',
    lite_access: 'Lite Access',
    operation_hour_date: 'Operation Hour Date',
    operation_hour_affordable_hour: 'Operation Hour Affordable Hour',
    operation_hour_remaining_hour: 'Operation Hour Remaining Hour',
    operation_hour_remaining_hour_status: 'Operation Hour Remaining Hour Status',
    fire_extinguisher_reminder_day: 'Fire Extinguisher Reminder Day',
    engine_date: 'Engine Date',
    engine_expiry_date: 'Engine Expiry Date',
    engine_affordable_mileage: 'Engine Affordable Mileage',
    engine_current_mileage: 'Engine Current Mileage',
    engine_remaining_mileage: 'Engine Remaining Mileage',
    engine_remaining_mileage_status: 'Engine Remaining Mileage Status',
    operation_hour_renew: 'Operation Hour Renew',
    last_operation_start_date: 'Last Operation Start Date',
    other_maintenance_affordable_engine_hours: 'Other Maintenance Affordable Engine Hours',
    fire_extinguisher_renew: 'Fire Extinguisher Renew',
    reminder_day: 'Reminder Day',
    engine_renew: 'Engine Renew',
    next_engine_maintenance_due_date: 'Next Engine Maintenance Due Date',
    current_mileage_maintenance_mileage_km: 'Current Mileage Maintenance Mileage (km)',
    engine_maitenance_affordable_mileage_km: 'Engine Maitenance Affordable Mileage (km)',
    delete_role: 'Delete Role',
    system_predefined: 'System Predefined',
    enable: 'Enable',
    other_1_title: 'Other 1 Document Type',
    other_1_type: 'Other 1 Type',
    other_2_title: 'Other 2 Document Type',
    other_2_type: 'Other 2 Type',
    other_3_title: 'Other 3 Document Type',
    other_3_type: 'Other 3 Type',
    other_4_title: 'Other 4 Document Type',
    other_4_type: 'Other 4 Type',
    affordable_hour: 'Affordable Hour',
    create_role: 'Create Role',
    update_role: 'Update Role',
    new_package: 'New Package',
    delete_package: 'Delete Package',
    create_package: 'Create Package',
    update_package: 'Update Package',
    package: 'Package',
    package_name: 'Package Name',
    package_details: 'Package Details',
    default_enable: 'Default Enable',
    expiry: 'Expiry',
    permit_reminder_day: 'Permit Reminder Day',
    roadtax_reminder_day: 'Roadtax Reminder Day',
    sgp_roadtax_reminder_day: 'SGP Roadtax Reminder Day',
    puspakom_reminder_day: 'Puspakom Reminder Day',
    service_reminder_day: 'Service Reminder Day',
    sgp_roadtax_attachment: 'SGP Roadtax Attachment',
    puspakom_attachment: 'Puspakom Attachment',
    service_attachment: 'Service Attachment',
    service_affordable_mileage: 'Service Affordable Mileage',
    service_current_mileage: 'Service Current Mileage',
    service_remaining_mileage: 'Service Remaining Mileage',
    sgp_roadtax_no: 'SGP Roadtax No',
    insurance_reserved_cost: 'Insurance Reserved Cost',
    insurance_reminder_day: 'Insurance Reminder Day',
    insurance_attachment: 'Insurance Attachment',
    update_documentation: 'Update Documentation',
    delete_attachment: 'Delete Attachment',
    file_size_exceeds_limit: 'File size exceeds limit (5mb)',
    please_select_billing_entity: 'Please select billing entity',
    please_enter_plate_no: 'Please enter plate no',
    energy_source: 'Energy Source',
    petrol: 'Petrol',
    diesel: 'Diesel',
    electric: 'Electric',
}