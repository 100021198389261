const routeRouter = {
  path: '/route',
  meta: {
    title: 'Route',
    icon: 'routeSide'
  },
  children: [
    {
      path: 'manage-route',
      component: () => import('@/views/route/ManageRoute/manage-route'),
      name: 'ManageRoute',
      meta: { title: 'ManageRoute' }
    },
    {
      path: 'manage-route-form',
      component: () => import('@/views/route/ManageRoute/component/manage-route-form'),
      name: 'ManageRouteForm',
      hidden: true,
      meta: { 
        title: 'ManageRouteForm', 
        icon: 'subsubmenu',
        activeMenu: '/route/manage-route',         
        enableBack: true,
        backPage: 'ManageRoute' }
    },
    {
      path: 'manage-route-detail/:id?',
      component: () => import('@/views/route/ManageRoute/component/manage-route-detail'),
      name: 'ManageRouteDetail',
      hidden: true,
      meta: { title: 'ManageRouteDetail' }
    },
    {
      path: 'edit-manage-route-detail/:id?',
      component: () => import('@/views/route/ManageRoute/component/edit-manage-route-detail'),
      name: 'EditManageRouteDetail',
      hidden: true,
      meta: { title: 'EditManageRouteDetail' }
    },
    {
      path: 'manage-route-group',
      component: () => import('@/views/route/ManageRouteGroup/manage-route-group'),
      name: 'ManageRouteGroup',
      meta: { title: 'ManageRouteGroup' }
    },
    {
      path: 'route-group-form',
      component: () => import('@/views/route/ManageRouteGroup/component/route-group-form'),
      name: 'RouteGroupForm',
      hidden: true,
      meta: { 
          title: 'RouteGroupForm', 
          
          activeMenu: '/route/manage-route-group',         
          enableBack: true,
          backPage: 'ManageRouteGroup'
      }
    },
    {
      path: 'route-group-detail/:id?',
      component: () => import('@/views/route/ManageRouteGroup/component/route-group-detail'),
      name: 'RouteGroupDetail',
      hidden: true,
      meta: { 
          title: 'RouteGroupDetail', 
          
          activeMenu: '/route/manage-route-group',         
          enableBack: true,
          backPage: 'ManageRouteGroup'
      }
    },
    
    {
      path: 'assign-route-group/:id?',
      component: () => import('@/views/route/ManageRouteGroup/component/assign-route-group'),
      name: 'AssignRouteGroup',
      hidden: true,
      meta: { 
          title: 'AssignRouteGroup', 
          
          activeMenu: '/route/manage-route-group',         
          enableBack: true,
          backPage: 'ManageRouteGroup'
      }
    },
  ]
}

export default routeRouter
