const addInfo = {
  title: 'SmartAnalytics',
  icon: 'smart-analytic'
}
const smartAnalyticsRouter = {
  path: '/smart-analytics',
  meta: {
    title: 'SmartAnalytics',
    icon: 'smart-analytic'
  },
  children: [
    {
      path: 'operational-performance',
      component: () => import('@/views/smart-analytics/OperationalPerformance/operational-performance'),
      name: 'OperationalPerformance',
      meta: { title: 'OperationalPerformance', parent: addInfo }
    },
    {
      path: 'vehicle-performance',
      component: () => import('@/views/smart-analytics/VehiclePerformance/vehicle-performance'),
      name: 'VehiclePerformance',
      meta: { title: 'VehiclePerformance', parent: addInfo }
    },
    {
      path: 'driver-performance',
      component: () => import('@/views/smart-analytics/DriverPerformance/driver-performance'),
      name: 'DriverPerformance',
      meta: { title: 'DriverPerformance', parent: addInfo }
    }
  ]
}

export default smartAnalyticsRouter