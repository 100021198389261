const safetyRouter = {
  path: '/safety',
  meta: {
    title: 'Safety',
    icon: 'safety'
  },
  children: [
    {
      path: 'safety',
      component: () => import('@/views/safety/overview/safety'),
      name: 'Safety',
      meta: { title: 'Safety' }
    },
    // {
    //   path: 'driver-performance',
    //   component: () => import('@/views/safety/driverPerformance/driver-performance'),
    //   name: 'DriverPerformance',
    //   meta: { title: 'DriverPerformance' }
    // },
    // {
    //   path: 'driver-performance-details/:id?',
    //   component: () => import('@/views/safety/driverPerformance/component/driver-performance-details'),
    //   name: 'DriverPerformanceDetails',
    //   hidden: true,
    //   meta: { title: 'DriverPerformanceDetails' }
    // },
    // {
    //   path: 'event-details',
    //   component: () => import('@/views/safety/safetyEvent/event-details'),
    //   name: 'EventDetails',
    //   meta: { title: 'EventDetails' }
    // },
    // {
    //   path: 'event-details-drawer/:id?',
    //   component: () => import('@/views/safety/safetyEvent/component/event-details-drawer'),
    //   name: 'EventDetailsDrawer',
    //   hidden: true,
    //   meta: { title: 'EventDetailsDrawer', activeMenu: '/event-details', enableBack: true, backPage: 'EventDetails' }
    // },
    {
      path: 'speeding',
      component: () => import('@/views/safety/speedingBehavior/speeding'),
      name: 'Speeding',
      meta: { title: 'Speeding' }
    },
  ]
}

export default safetyRouter
