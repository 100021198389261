const addInfo = {
    title: 'Vehicle',
    icon: 'vehicle'
}

const vehicleRouter = {
    path: '/vehicle',
    meta: {
        title: 'Vehicle',
        icon: 'vehicle'
    },
    children: [
        {
            path: 'manage-vehicle',
            component: () => import('@/views/vehicle/ManageVehicle/manage-vehicle'),
            name: 'ManageVehicle',
            meta: { title: 'ManageVehicle', parent: addInfo }
        },
        {
            path: 'vehicle-form/:id?',
            component: () => import('@/views/vehicle/ManageVehicle/vehicle-form'),
            name: 'VehicleForm',
            hidden: true,
            meta: { 
                title: 'VehicleForm', 
                
                activeMenu: '/vehicle/manage-vehicle',         
                enableBack: true,
                backPage: 'ManageVehicle',
                parent: addInfo,
                parentIcon: 'vehicle'
            }
        },
        {
            path: 'manage-tracker',
            component: () => import('@/views/vehicle/ManageTracker/manage-tracker'),
            name: 'ManageTracker',
            meta: { title: 'ManageTracker', parent: addInfo }
        },
        {
            path: 'tracker-form/:id?',
            component: () => import('@/views/vehicle/ManageTracker/tracker-form'),
            name: 'TrackerForm',
            hidden: true,
            meta: { title: 'TrackerForm', parent: addInfo, activeMenu: '/vehicle/manage-tracker', enableBack: true, backPage: 'ManageTracker' }
        },
        {
            path: 'maintenance-log',
            name: 'MaintenanceLog',
            meta: { title: 'MaintenanceLog' },
            children: [
                {
                    path: 'details/:id',
                    component: () => import('@/views/vehicle/MaintenanceLog/maintenance-log'),
                    name: 'MaintenanceDetails',
                    hidden: true,
                    meta: { title: 'MaintenanceDetails', parent: addInfo, activeMenu: '/vehicle/manage-vehicle' }
                },
                {
                    path: 'overview',
                    component: () => import('@/views/vehicle/MaintenanceLog/overview'),
                    name: 'MaintenanceLogOverview',
                    meta: { title: 'Overview', parent: addInfo }
                },
                {
                    path: 'service-log',
                    component: () => import('@/views/vehicle/MaintenanceLog/service-log'),
                    name: 'ServiceLog',
                    meta: { title: 'ServiceLog', parent: addInfo }
                },
                {
                    path: 'vehicle-log',
                    component: () => import('@/views/vehicle/MaintenanceLog/vehicle-log'),
                    name: 'VehicleLog',
                    meta: { title: 'VehicleLog', parent: addInfo }
                },
            ]
        },
        {
            path: 'maintenance-form-list',
            component: () => import('@/views/vehicle/MaintenanceForm/maintenance-form-list'),
            name: 'MaintenanceFormList',
            meta: { title: 'MaintenanceForm', parent: addInfo }
        },
        {
            path: 'maintenance-form/:id?',
            component: () => import('@/views/vehicle/MaintenanceForm/maintenance-form'),
            name: 'MaintenanceForm',
            hidden: true,
            meta: { title: 'MaintenanceForm', parent: addInfo, activeMenu: '/vehicle/maintenance-form-list', enableBack: true, backPage: 'MaintenanceFormList' }
        },
        {
            path: 'tools',
            name: 'Tools',
            meta: { title: 'Tools' },
            children: [
                {
                    path: 'maintenance-category',
                    component: () => import('@/views/vehicle/Tools/maintenance-category'),
                    name: 'MaintenanceCategory',
                    meta: { title: 'MaintenanceCategory', parent: addInfo }
                },
                {
                    path: 'vehicle-industry',
                    component: () => import('@/views/vehicle/Tools/vehicle-industry'),
                    name: 'VehicleIndustry',
                    meta: { title: 'VehicleIndustry', parent: addInfo }
                },
            ]
        },
    ]
}

export default vehicleRouter
