const insuranceRouter = {
  path: '/insurance',
  meta: {
    title: 'Insurance',
    icon: 'insurance'
  },
  children: [
    {
      path: 'insurance',
      component: () => import('@/views/insurance/insurance'),
      name: 'Insurance',
      meta: { title: 'Insurance' }
    },
    // {
    //   path: 'insurance-detail/:id?',
    //   component: () => import('@/views/insurance/insurance-detail'),
    //   name: 'InsuranceDetail',
    //   hidden: true,
    //   meta: { title: 'InsuranceDetails', activeMenu: '/insurance', enableBack: true, backPage: 'Insurance' }
    // },
  ]
}

export default insuranceRouter
